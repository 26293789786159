<template>
	<div>
		<div class="ser-form">
			<Form ref="form" :model="search" inline>
				<FormItem>
					<Input v-model="search.name" placeholder="型号名称" />
				</FormItem>
				<FormItem>
					<Button type="primary" icon="md-search" @click="init()">搜索</Button>
				</FormItem>
				<FormItem>
					<Button type="primary" icon="md-add" @click="edit_data()">新增型号</Button>
				</FormItem>
			</Form>
		</div>
		<div class="table-list">
			<Table :loading="loading" :columns="columns" :data="data">
				<template slot-scope="{row}" slot="status">
					<Tag :color="row.status ? 'success' : 'error'">{{ row.status ? '显示' : '隐藏' }}</Tag>
				</template>
				<template #picture="{row}">
					<img v-if="row.picture" :src="row.picture" style="width: 75px; height: 75px;">
				</template>
				<template slot-scope="{row}" slot="operate">
					<Button @click="edit_data(row)">编辑</Button>
				</template>
			</Table>
		</div>
		<div class="ser-page">
			<Page class-name="page-r" :total="search.total" :current="search.page" :page-size="search.pageSize"
				:page-size-opts="[15,30,50]" @on-change="changePage" @on-page-size-change="changePageSize" show-total
				show-sizer></Page>
		</div>
		<Modal v-model="editModal.show" :title="(editModal.data.id ? '编辑' : '添加')+'型号'">
			<Form :model="editModal.data" :label-width="120" label-colon>
				<FormItem label="型号名称">
					<Input v-model="editModal.data.name"></Input>
				</FormItem>
				<FormItem label="选择厂家">
					<Select v-model="editModal.data.brand_id" @on-change="selectBrand"
						:disabled="editModal.data.id ? true : false">
						<Option v-for="(item,index) in brand" :key="index" :value="item.value">{{ item.label }}</Option>
					</Select>
				</FormItem>
				<FormItem label="设备分类">
					<Select v-model="editModal.data.cate_id"
						:disabled="(editModal.data.id ? true : false) || (editModal.data.brand_id ? false : true)">
						<Option v-for="(item,index) in category" :key="index" :value="item.value">{{ item.label }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="型号图片">
					<div style="display: flex;">
						<img v-if="editModal.data.picture" :src="editModal.data.picture"
							style="width: 77px; height: 77px; margin-right: 16px;">
						<Uploads :action="ApiUrl+'/adm/upload_img'" :data="{dir:'deviceType'}"
							@upload_succ="uploads_succ" style="width: 77px;"></Uploads>
					</div>
				</FormItem>
				<FormItem label="是否显示">
					<RadioGroup v-model="editModal.data.status">
						<Radio :label="1">显示</Radio>
						<Radio :label="0">隐藏</Radio>
					</RadioGroup>
				</FormItem>
			</Form>
			<template slot="footer">
				<Button @click="editModal.show = false" style="margin-right: 10px;">取消</Button>
				<Button @click="confirm_edit_data()" type="primary">确认</Button>
			</template>
		</Modal>
	</div>
</template>

<script>
	var category, add_data = {
		id: null,
		name: '',
		brand_id: 0,
		cate_id: 0,
		status: 1,
		picture: ''
	};
	import Uploads from '../../../components/common/Uploads';
	export default {
		name: "DeviceType",
		components: {
			Uploads
		},
		data() {
			return {
				loading: false,
				search: {
					name: '',
					page: 1,
					pageSize: 15,
					total: 0
				},
				editModal: {
					show: false,
					data: JSON.parse(JSON.stringify(add_data))
				},
				columns: [
					// { type: 'selection', width: 60},
					{
						title: 'ID',
						key: 'id',
						minWidth: 80,
						tree: true
					},
					{
						title: '图片',
						slot: 'picture',
						minWidth: 80
					},
					{
						title: '所属厂家',
						key: 'brand_name',
						minWidth: 100
					},
					{
						title: '所属设备',
						key: 'cate_name',
						minWidth: 100
					},
					{
						title: '型号名称',
						key: 'name',
						minWidth: 100
					},
					{
						title: '状态',
						slot: 'status',
						minWidth: 80
					},
					{
						title: '添加时间',
						key: 'create_time',
						minWidth: 160
					},
					// { title: '排序', key: 'sort', minWidth: 70},
					{
						title: '操作',
						slot: 'operate',
						minWidth: 170
					}
				],
				data: [],
				brand: [], // 设备厂家
				category: [], // 设备分类
			}
		},
		created() {
			this.init();
		},
		methods: {
			init() {
				var _this = this;
				this.requestApi('/adm/get_type_list', this.search).then(function(res) {
					if (res.status) {
						_this.data = res.data.data;
						_this.search.total = res.data.total;
						_this.brand = res.brand;
						category = res.category;
					}
				})
			},
			// 新增/编辑 设备分类
			edit_data(data) {
				if (data) {
					this.category = JSON.parse(JSON.stringify(category));
					for (let index in this.editModal.data) {
						this.editModal.data[index] = data[index];
					}
				} else {
					this.editModal.data = JSON.parse(JSON.stringify(add_data));
				}
				this.editModal.show = true;
			},
			// 确认 添加/编辑 设备分类
			confirm_edit_data() {
				var _this = this;
				this.requestApi('/adm/edit_device_type', {
					data: this.editModal.data
				}).then((res) => {
					if (res.status) {
						_this.editModal.show = false;
						_this.alertSucc('保存成功');
						_this.init();
					} else {
						_this.alertErr(res.msg);
					}
				})
			},
			// 选择厂家
			selectBrand(brandId) {
				if (brandId) {
					var data = {};
					this.category = [];
					for (let key in this.brand) {
						if (this.brand[key].value == brandId) {
							data = this.brand[key];
						}
					}
					for (let index in category) {
						if (data.device_type.indexOf(String(category[index].value - 1)) != -1) {
							this.category.push(category[index]);
						}
					}
				}
			},
			changePage(page) {
				this.search.page = page;
				this.init();
			},
			changePageSize(size) {
				this.search.page = 1;
				this.search.pageSize = size;
				this.init();
			},
			// 上传图片回调
			uploads_succ(res) {
				if (res.status === 1) {
					this.editModal.data.picture = this.fullImage(res.pic_url);
				} else {
					this.alertErr(res.msg);
				}

			},
		}
	}
</script>

<style scoped>

</style>